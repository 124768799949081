/*
 * Common job, group and count styling
 */

.job-group {
  margin: 0;
  cursor: default;
}

.job-btn {
  background: transparent;
  padding: 0 2px 0 2px;
  vertical-align: 0;
  line-height: 1.32;
  display: none;
  transition: transform 0.1s;
  font-size: 12px;
}

.group-btn {
  background: transparent;
  padding: 0 2px 0 2px;
  vertical-align: 0;
  line-height: 1.32;
  cursor: pointer;
  font-size: 12px;
}

.group-btn::before {
  content: '+';
}

.group-btn.btn.job-group-count:hover {
  background-color: rgba(219, 235, 252, 0.51);
}

.group-symbol {
  background: transparent;
  padding: 0 2px 0 2px;
  border: 0;
  vertical-align: 0;
  line-height: 1.32;
  cursor: pointer;
  margin-right: -3px;
}

.group-symbol:hover {
  cursor: pointer;
}

.group-content {
  margin: 0;
  white-space: nowrap;
}

.group-content::before {
  content: '(';
  padding-left: 2px;
  padding-right: 2px;
}

.group-content::after {
  content: ')';
  margin-right: 2px;
  padding-left: 2px;
}

.group-job-list {
  margin-left: -3px;
  white-space: normal;
}

.runnable-job-btn {
  display: none;
  color: rgba(128, 128, 0, 0.5);
  margin: 0;
  background: transparent;
  padding: 0 2px;
  vertical-align: 0;
  line-height: 1.32;
  font-size: 12px;
  border-radius: 0;
  text-align: center;
  white-space: nowrap;
}

.runnable-job-btn-selected {
  outline: 1px solid;
  outline-radius: 3px;
  overflow: visible;
}

.selected-job {
  outline: 4px solid;
  background-color: #fff;
}

.selected-count {
  background-color: #ffffffb3;
}

.btn-lg-xform {
  outline: 2px solid;
  outline-radius: 3px;
  font-size: 12px;
  z-index: 1;
  transition: 0.1s;
  transform: scale(1.3, 1.3);
}

.btn-lg-xform:hover {
  opacity: 0.9;
}

.filter-shown {
  display: inline-block;
}

/*
 * Job button colors and classification states
 */

.job-btn.btn-dkgray,
.job-btn.btn-ltgray,
.job-btn.btn-green,
.job-btn.btn-dkblue,
.job-btn.btn-ltblue,
.job-btn.btn-yellow,
.job-btn.btn-pink,
.job-btn.btn-dkgray-classified,
.job-btn.btn-ltgray-classified,
.job-btn.btn-green-classified,
.job-btn.btn-dkblue-classified,
.job-btn.btn-ltblue-classified,
.job-btn.btn-yellow-classified,
.job-btn.btn-pink-classified,
.job-btn.btn-red-classified,
.job-btn.btn-orange-classified,
.job-btn.btn-purple-classified,
.group-btn.btn-dkgray-count,
.group-btn.btn-ltgray-count,
.group-btn.btn-green-count,
.group-btn.btn-dkblue-count,
.group-btn.btn-ltblue-count,
.group-btn.btn-yellow-count,
.group-btn.btn-pink-count {
  margin: 0 -2px 0 0;
}

.job-btn.btn-red,
.job-btn.btn-orange,
.job-btn.btn-yellow,
.job-btn.btn-purple {
  margin: 0 -1px 1px 1px;
}

.classified-icon,
.intermittent-icon {
  font-size: 0.62em;
}

.classified-icon {
  vertical-align: super;
}

.intermittent-icon {
  vertical-align: sub;
}

/* Bold buttons */

.btn-green,
.btn-orange,
.btn-purple,
.btn-red,
.btn-dkblue,
.btn-pink,
.btn-yellow,
.btn-ltblue {
  font-weight: bold;
}

/* Orange, testfailed */
.btn-orange {
  background-color: var(--failure-bg-color);
  color: var(--failure-color);
}
.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active {
  background-color: #c15700;
  color: white;
}
.btn-orange.disabled:hover,
.btn-orange.disabled:focus,
.btn-orange.disabled:active,
.btn-orange.disabled.active,
.btn-orange:disabled:hover,
.btn-orange:disabled:focus,
.btn-orange:disabled:active,
.btn-orange:disabled.active,
fieldset[disabled] .btn-orange:hover,
fieldset[disabled] .btn-orange:focus,
fieldset[disabled] .btn-orange:active,
fieldset[disabled] .btn-orange.active {
  background-color: #dd6602;
  border-color: #dd6602;
}

/* Orange, testfailed classified */
.btn-orange-classified,
.btn-orange-classified-count {
  color: #b45303;
}
.btn-orange-classified:hover,
.btn-orange-classified:focus,
.btn-orange-classified:active,
.btn-orange-classified.active {
  background-color: #b45303;
  border-color: #aa4f02;
  color: white;
}
.btn-orange-classified-count:hover {
  color: #aa4f02;
}
.btn-orange-classified.disabled:hover,
.btn-orange-classified.disabled:focus,
.btn-orange-classified.disabled:active,
.btn-orange-classified.disabled.active,
.btn-orange-classified:disabled:hover,
.btn-orange-classified:disabled:focus,
.btn-orange-classified:disabled:active,
.btn-orange-classified:disabled.active,
fieldset[disabled] .btn-orange-classified:hover,
fieldset[disabled] .btn-orange-classified:focus,
fieldset[disabled] .btn-orange-classified:active,
fieldset[disabled] .btn-orange-classified.active {
  background-color: #dd6602;
  border-color: #dd6602;
}

/* Red, busted */
.btn-red {
  background-color: #b74c4c;
  color: white;
}
.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active {
  background-color: #a1020e;
  color: white;
}
.btn-red.disabled:hover,
.btn-red.disabled:focus,
.btn-red.disabled:active,
.btn-red.disabled.active,
.btn-red:disabled:hover,
.btn-red:disabled:focus,
.btn-red:disabled:active,
.btn-red:disabled.active,
fieldset[disabled] .btn-red:hover,
fieldset[disabled] .btn-red:focus,
fieldset[disabled] .btn-red:active,
fieldset[disabled] .btn-red.active {
  background-color: #c2020e;
  border-color: #c2020e;
}

/* Red, busted classified */
.btn-red-classified,
.btn-red-classified-count,
.btn-red-classified-count:hover {
  color: #90010a;
}
.btn-red-classified:hover,
.btn-red-classified:focus,
.btn-red-classified:active,
.btn-red-classified.active {
  background-color: #a9020c;
  border-color: #90010a;
  color: white;
}
.btn-red-classified.disabled:hover,
.btn-red-classified.disabled:focus,
.btn-red-classified.disabled:active,
.btn-red-classified.disabled.active,
.btn-red-classified:disabled:hover,
.btn-red-classified:disabled:focus,
.btn-red-classified:disabled:active,
.btn-red-classified:disabled.active,
fieldset[disabled] .btn-red-classified:hover,
fieldset[disabled] .btn-red-classified:focus,
fieldset[disabled] .btn-red-classified:active,
fieldset[disabled] .btn-red-classified.active {
  background-color: #c2020e;
  border-color: #c2020e;
  color: white;
}

/* Dark blue, retry */
.btn-dkblue,
.btn-dkblue-count,
.btn-dkblue-classified,
.btn-dkblue-classified-count,
.btn-dkblue-count:hover,
.btn-dkblue-classified-count:hover {
  color: #283aa2;
  font-weight: bold;
}
.btn-dkblue:hover,
.btn-dkblue-classified:hover,
.btn-dkblue:focus,
.btn-dkblue:active,
.btn-dkblue.active {
  border-color: #263fc3;
  background-color: #263fc3;
  color: white;
  font-weight: bold;
}
.btn-dkblue.disabled:hover,
.btn-dkblue.disabled:focus,
.btn-dkblue.disabled:active,
.btn-dkblue.disabled.active,
.btn-dkblue:disabled:hover,
.btn-dkblue:disabled:focus,
.btn-dkblue:disabled:active,
.btn-dkblue:disabled.active,
fieldset[disabled] .btn-dkblue:hover,
fieldset[disabled] .btn-dkblue:focus,
fieldset[disabled] .btn-dkblue:active,
fieldset[disabled] .btn-dkblue.active {
  background-color: #2d48d6;
  border-color: #2d48d6;
}

/* Green, success */
.btn-green,
.btn-green-count,
.btn-green-classified,
.btn-green-classified-count,
.btn-green-count:hover,
.btn-green-classified-count:hover {
  color: rgba(2, 130, 51, 0.95);
  font-weight: bold;
}
.btn-green:hover,
.btn-green-classified:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active {
  border-color: #019029;
  background-color: #017722;
  color: white;
}
.btn-green.disabled:hover,
.btn-green.disabled:focus,
.btn-green.disabled:active,
.btn-green.disabled.active,
.btn-green:disabled:hover,
.btn-green:disabled:focus,
.btn-green:disabled:active,
.btn-green:disabled.active,
fieldset[disabled] .btn-green:hover,
fieldset[disabled] .btn-green:focus,
fieldset[disabled] .btn-green:active,
fieldset[disabled] .btn-green.active {
  border-color: #02c238;
}

/* Purple, infrastructure exception */
.btn-purple {
  background-color: #9a7da6;
  color: #3a004d;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active {
  background-color: #3a004d;
  color: white;
}
.btn-purple.disabled:hover,
.btn-purple.disabled:focus,
.btn-purple.disabled:active,
.btn-purple.disabled.active,
.btn-purple:disabled:hover,
.btn-purple:disabled:focus,
.btn-purple:disabled:active,
.btn-purple:disabled.active,
fieldset[disabled] .btn-purple:hover,
fieldset[disabled] .btn-purple:focus,
fieldset[disabled] .btn-purple:active,
fieldset[disabled] .btn-purple.active {
  background-color: #9002c2;
  border-color: #9002c2;
  color: white;
}

/* Purple, infrastructure exception classified */
.btn-purple-classified,
.btn-purple-classified-count,
.btn-purple-classified-count:hover {
  color: #6f0296;
}
.btn-purple-classified:hover,
.btn-purple-classified:focus,
.btn-purple-classified:active,
.btn-purple-classified.active {
  background-color: #7d02a9;
  border-color: #6b0190;
  color: white;
}
.btn-purple-classified.disabled:hover,
.btn-purple-classified.disabled:focus,
.btn-purple-classified.disabled:active,
.btn-purple-classified.disabled.active,
.btn-purple-classified:disabled:hover,
.btn-purple-classified:disabled:focus,
.btn-purple-classified:disabled:active,
.btn-purple-classified:disabled.active,
fieldset[disabled] .btn-purple-classified:hover,
fieldset[disabled] .btn-purple-classified:focus,
fieldset[disabled] .btn-purple-classified:active,
fieldset[disabled] .btn-purple-classified.active {
  background-color: #9002c2;
  border-color: #9002c2;
  color: white;
}

/* Light blue, superseded */
.btn-ltblue,
.btn-ltblue-count,
.btn-ltblue-classified,
.btn-ltblue-classified-count,
.btn-ltblue-count:hover,
.btn-ltblue-classified-count:hover {
  color: #3f77c6;
}
.btn-ltblue:hover,
.btn-ltblue-classified:hover,
.btn-ltblue:focus,
.btn-ltblue:active,
.btn-ltblue.active {
  border-color: #3f77c6;
  background-color: #3f77c6;
  color: white;
}
.btn-ltblue.disabled:hover,
.btn-ltblue.disabled:focus,
.btn-ltblue.disabled:active,
.btn-ltblue.disabled.active,
.btn-ltblue:disabled:hover,
.btn-ltblue:disabled:focus,
.btn-ltblue:disabled:active,
.btn-ltblue:disabled.active,
fieldset[disabled] .btn-ltblue:hover,
fieldset[disabled] .btn-ltblue:focus,
fieldset[disabled] .btn-ltblue:active,
fieldset[disabled] .btn-ltblue.active {
  border-color: #81b8ed;
}

/* Light gray, pending */
.btn-ltgray,
.btn-ltgray-count,
.btn-ltgray-classified,
.btn-ltgray-classified-count {
  color: #757575;
}
.btn-ltgray-count:hover,
.btn-ltgray-classified-count:hover {
  color: #808080;
}
.btn-ltgray:hover,
.btn-ltgray-classified:hover,
.btn-ltgray:focus,
.btn-ltgray:active,
.btn-ltgray.active {
  border-color: #808080;
  color: #808080;
}
.btn-ltgray.disabled:hover,
.btn-ltgray.disabled:focus,
.btn-ltgray.disabled:active,
.btn-ltgray.disabled.active,
.btn-ltgray:disabled:hover,
.btn-ltgray:disabled:focus,
.btn-ltgray:disabled:active,
.btn-ltgray:disabled.active,
fieldset[disabled] .btn-ltgray:hover,
fieldset[disabled] .btn-ltgray:focus,
fieldset[disabled] .btn-ltgray:active,
fieldset[disabled] .btn-ltgray.active {
  color: #e0e0e0;
  border-color: #e0e0e0;
}

/* Mid gray (presently unused) */
.btn-mdgray,
.btn-mdgray-count,
.btn-mdgray-classified,
.btn-mdgray-classified-count,
.btn-mdgray-count:hover,
.btn-mdgray-classified-count:hover {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}
.btn-mdgray:hover,
.btn-mdgray-classified:hover,
.btn-mdgray:focus,
.btn-mdgray:active,
.btn-mdgray.active {
  background-color: #b2b2b2;
  border-color: #a6a6a6;
}
.btn-mdgray.disabled:hover,
.btn-mdgray.disabled:focus,
.btn-mdgray.disabled:active,
.btn-mdgray.disabled.active,
.btn-mdgray:disabled:hover,
.btn-mdgray:disabled:focus,
.btn-mdgray:disabled:active,
.btn-mdgray:disabled.active,
fieldset[disabled] .btn-mdgray:hover,
fieldset[disabled] .btn-mdgray:focus,
fieldset[disabled] .btn-mdgray:active,
fieldset[disabled] .btn-mdgray.active {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

/* Dark gray, running */
.btn-dkgray,
.btn-dkgray-count,
.btn-dkgray-classified,
.btn-dkgray-classified-count,
.btn-dkgray-count:hover,
.btn-dkgray-classified-count:hover {
  color: black;
}
.btn-dkgray:hover,
.btn-dkgray-classified:hover,
.btn-dkgray:focus,
.btn-dkgray:active,
.btn-dkgray.active {
  background-color: black;
  border-color: black;
  color: white;
}
.btn-dkgray.disabled:hover,
.btn-dkgray.disabled:focus,
.btn-dkgray.disabled:active,
.btn-dkgray.disabled.active,
.btn-dkgray:disabled:hover,
.btn-dkgray:disabled:focus,
.btn-dkgray:disabled:active,
.btn-dkgray:disabled.active,
fieldset[disabled] .btn-dkgray:hover,
fieldset[disabled] .btn-dkgray:focus,
fieldset[disabled] .btn-dkgray:active,
fieldset[disabled] .btn-dkgray.active {
  background-color: #7c7a7d;
  border-color: #7c7a7d;
  color: white;
}

/* Yellow, unknown */
.btn-yellow,
.btn-yellow-count,
.btn-yellow-classified,
.btn-yellow-classified-count {
  background-color: #fcf8e3;
  border-color: #fbd890;
  color: #8a6d3b;
}
.btn-yellow-count:hover,
.btn-yellow-classified-count:hover {
  color: #8a6d3b;
}
.btn-yellow:hover,
.btn-yellow-classified:hover,
.btn-yellow:focus,
.btn-yellow:active,
.btn-yellow.active {
  background-color: #8a6d3b;
  border-color: #fbd890;
  color: #fcf8e3;
}
.btn-yellow.disabled:hover,
.btn-yellow.disabled:focus,
.btn-yellow.disabled:active,
.btn-yellow.disabled.active,
.btn-yellow:disabled:hover,
.btn-yellow:disabled:focus,
.btn-yellow:disabled:active,
.btn-yellow:disabled.active,
fieldset[disabled] .btn-yellow:hover,
fieldset[disabled] .btn-yellow:focus,
fieldset[disabled] .btn-yellow:active,
fieldset[disabled] .btn-yellow.active {
  background-color: #fcf8e3;
  border-color: #fbd890;
}

/* Pink, cancelled */
.btn-pink,
.btn-pink-count,
.btn-pink-classified,
.btn-pink-classified-count,
.btn-pink-count:hover,
.btn-pink-classified-count:hover {
  color: #cf2b9f;
}
.btn-pink:hover,
.btn-pink-classified:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active {
  background-color: #cf2b9f;
  border-color: #cf2b9f;
  color: white;
}
.btn-pink.disabled:hover,
.btn-pink.disabled:focus,
.btn-pink.disabled:active,
.btn-pink.disabled.active,
.btn-pink:disabled:hover,
.btn-pink:disabled:focus,
.btn-pink:disabled:active,
.btn-pink:disabled.active,
fieldset[disabled] .btn-pink:hover,
fieldset[disabled] .btn-pink:focus,
fieldset[disabled] .btn-pink:active,
fieldset[disabled] .btn-pink.active {
  background-color: #ff80e5;
  border-color: #ff80e5;
}

.group-select-all-runnable {
  color: rgba(128, 128, 0, 0.5);
  font-size: 12px;
  background: transparent;
  padding: 0 2px 0 2px;
  border: 0;
  vertical-align: 0;
  line-height: 1.32;
  cursor: pointer;
  margin-right: -3px;
}

.group-select-all-runnable:hover {
  cursor: pointer;
}
